import * as React from "react";
import {
  Box,
  Flex,
  HStack,
  useDisclosure,
  Image,
  Text,
  Popover,
  PopoverTrigger,
  Button,
  PopoverContent,
  PopoverCloseButton,
  PopoverArrow,
  PopoverHeader,
  PopoverBody,
} from "@chakra-ui/react";
import { Link } from "gatsby";
import { HamburgerIcon } from "@chakra-ui/icons";
import Logo from "../../images/Logoo.png";
import { useLocation } from "@reach/router";
import "./Header.css";

interface Props {
  children: React.ReactNode;
  path: string;
  pathname: string;
  scrolling: boolean;
}

const Links = ["About", "Contact"];

// const NavLink = (props: Props) => {
//   const { children, path, pathname, scrolling } = props;
//   return (
//     <Link to={`/${path.toLocaleLowerCase()}`}>
//       <Box
//         className={
//           pathname === "/" && !scrolling
//             ? "hover-underline-animation landing-page-nav"
//             : "hover-underline-animation"
//         }
//         fontSize="1rem"
//         m="0 1rem"
//         rounded="md"
//         color={pathname === "/" && !scrolling ? "#fff" : "#28329D"}
//         _hover={{
//           textDecoration: "none",
//           color: pathname === "/" && !scrolling ? "#fff" : "#D65050",
//         }}
//       >
//         {children}
//       </Box>
//     </Link>
//   );
// };

const Header = () => {
  const { isOpen, onOpen, onClose } = useDisclosure();
  const { pathname } = useLocation();

  const handleClick = () => {
    onOpen();
  };

  const [scrolling, setScrolling] = React.useState(false);

  React.useEffect(() => {
    // Add a scroll event listener to the window
    window.addEventListener("scroll", handleScroll);

    // Remove the event listener when the component unmounts
    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, []);

  const handleScroll = () => {
    // Check the scroll position and update the state
    if (window.scrollY > 100) {
      setScrolling(true);
    } else {
      setScrolling(false);
    }
  };

  return (
    <>
      <Box
        transition={"background-color 0.5s ease"}
        position={pathname === "/contact/" ? "fixed" : "sticky"}
        background={"#000"}
        top={0}
        width="100%"
        p={6}
        zIndex={111}
        // maxW={"8xl"}
      >
        <Flex h="50px" alignItems={"center"} justifyContent={"space-between"}>
          {/* <HamburgerIcon
            aria-label={"Open Menu"}
            ml="1rem"
            fontSize="2xl"
            display={{ md: "none" }}
            transition={"background-color 0.5s ease"}
            color={pathname === "/" && !scrolling ? "#fff" : "#000"}
            onClick={handleClick}
          /> */}
          <HStack
            alignItems="center"
            width="100%"
            display="flex"
            height="100%"
            ml={50}
          >
            <Image h={14} src={Logo} />
            <Box
              // marginLeft={{ base: 10, sm: 10, md: 50 }}
              transition={"background-color 0.3s ease"}
              display={"flex"}
              flexDir={"column"}
              justifyContent={"center"}
              // alignItems={"center"}
            >
              <Link to="/">
                <Text fontSize={"2xl"} color={"white"}>
                  Calories+
                </Text>
              </Link>
              <Text fontSize={"xs"} color={"white"}>
                A product of{" "}
                <a
                  style={{ textDecoration: "underline" }}
                  target="_blank"
                  href="https://syncstack.pages.dev"
                >
                  SyncStack
                </a>
              </Text>
            </Box>
          </HStack>
        </Flex>
      </Box>
    </>
  );
};
export default Header;
